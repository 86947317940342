
import {
  Vue, Component, Prop, Watch
} from 'vue-property-decorator';
import zxcvbn from '@zxcvbn-ts/core';

@Component({})
export default class PasswordMeter extends Vue {
  @Prop() value!: string;
  @Prop() userId!: string;
  @Prop() userCd!: string;
  score: number = -1;

  @Watch('value')
  onValueChanged():void {
    if (this.value === '') {
      this.score = -1;
      return;
    }
    const result = zxcvbn(this.value, { dictionary: { userInpts: [this.userId, this.userCd] } });
    this.score = result.score;
    this.$emit('score', this.score);
  }

  scoreText(): string {
    if (this.score === -1) return '';
    if (this.score < 2) return '弱';
    if (this.score < 4) return '中';
    return '強';
  }
}
